import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { colors, theme } from "../../styles/theme";
import { Button, transition } from "../../styles";
import Carousel from "../Global/Carousel.component";
import { title } from "process";

const ServicesSection = () => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [percentageVisible, setPercentageVisible] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      // Define the media query you want to check (e.g., max-width: 768px for mobile)
      const mediaQuery = window.matchMedia("(max-width: 768px)");
      setIsMobile(mediaQuery.matches);
    };

    // Add event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial value
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const targetRect = targetRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Calculate the percentage of the target element that is visible in the viewport
        const visibleHeight =
          Math.min(targetRect.bottom, windowHeight) -
          Math.max(targetRect.top, 0);
        const percent = (visibleHeight / targetRect.height) * 100;

        setPercentageVisible(percent);
      }
    };

    // Attach the global scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getRadius = (percentage: number) => {
    return 100 - (percentage / 100) * (isMobile ? 50 : 50) + "%";
  };

  return (
    <ServicesSectionContainer
      ref={targetRef}
      style={{
        borderRadius: `0 ${getRadius(percentageVisible)} 0
         0`,
      }}
      id="services"
    >
      <Title style={{ width: "100%", marginBottom: 50 }} data-aos="fade">
        WHAT WE OFFER?
      </Title>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 10,
          color: "#888888",
          fontFamily: "NeueHaasDisplay-Light",
          fontSize: isMobile ? 25 : 40,
        }}
      >
        <span
          style={{
            display: "flex",
            width: "max-content",
            margin: "0 0 20px 0",
          }}
          data-aos="fade-right"
        >
          Web App Development{" "}
          <span style={{ fontSize: 18, fontStyle: "italic" }}>01</span>/
        </span>

        <span
          style={{
            display: "flex",
            width: "max-content",
            margin: "0 0 20px 0",
          }}
          data-aos="fade-left"
        >
          Mobile App Development{" "}
          <span style={{ fontSize: 18, fontStyle: "italic" }}>02</span>/
        </span>

        <span
          style={{
            display: "flex",
            width: "max-content",
            margin: "0 0 20px 0",
          }}
          data-aos="fade-right"
        >
          Server-side App Development{" "}
          <span style={{ fontSize: 18, fontStyle: "italic" }}>03</span>/
        </span>

        <span
          style={{
            display: "flex",
            width: "max-content",
            margin: "0 0 20px 0",
          }}
          data-aos="fade-left"
        >
          Data Science{" "}
          <span style={{ fontSize: 18, fontStyle: "italic" }}>04</span>/
        </span>

        <span
          style={{
            display: "flex",
            width: "max-content",
            margin: "0 0 20px 0",
          }}
          data-aos="fade-left"
        >
          Artificial Intelligence
          <span style={{ fontSize: 18, fontStyle: "italic" }}>05</span>/
        </span>

        <span
          style={{
            display: "flex",
            width: "max-content",
            margin: "0 0 20px 0",
          }}
          data-aos="fade-right"
        >
          Technology Consultation{" "}
          <span style={{ fontSize: 18, fontStyle: "italic" }}>06</span>
        </span>
      </div>
    </ServicesSectionContainer>
  );
};

const ServicesSectionContainer = styled.div`
  height: 100vh;
  padding: 1.5% 1.5% 0 5%;
  gap: 5%;
  background-color: #151515;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: 100vh;
    height: max-content;
    flex-direction: column;
  }
`;

export const Title = styled.p`
  color: white;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const ImagesContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BackgroundBlur = styled.span<{ percentage: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  background-color: rgba(0, 0, 0, ${({ percentage }) => percentage});
`;

interface IServiceImage {
  image: any;
  width: number;
}

const ServiceImage = styled.div<IServiceImage>`
  position: relative;
  height: 100%;
  width: ${({ width }) => width + "%"};
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px 30px 0 0;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 600px;
    width: 100%;
  }
`;

export default ServicesSection;
