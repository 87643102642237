import { styled } from "styled-components";
import { Button, Link, transition } from "../../styles";
import { TbWorld } from "react-icons/tb";
import { colors } from "../../styles/theme";
import { CgArrowTopRight } from "react-icons/cg";
import TestimonySection from "./TestimonySection.component";

const ProjectsSection = () => {
  const ButtonProps = {
    border: "1px solid white",
  };

  return (
    <ProjectsSectionContainer id="portfolio">
      <TopSectionContainer>
        <BottomSectionDiv
          width={35}
          className="project_container"
          data-aos="fade-right"
        >
          <ProjectBackgroundImg
            alt="Kenny royal background"
            src={require("../../assets/images/projects/kennyroyal/background.jpeg")}
          />
          <ProjectDesc>
            <ProjectName>Kenny royal</ProjectName>
            <ProjectP>
              A freight shipping company, with a long-standing reputation for
              excellence, diligently caters to a vast network of international
              customers seeking reliable shipping solutions.
            </ProjectP>
            <div
              style={{
                position: "absolute",
                bottom: "7.5%",
                left: "7.5%",
                right: "7.5%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link
                to={"http://kennyroyal.com/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  props={{
                    color: "white",
                    hover: "transparent",
                    hoverColor: "white",
                  }}
                  style={ButtonProps}
                >
                  <TbWorld /> website
                </Button>
              </Link>
              <img
                src={require("../../assets/images/projects/kennyroyal/logo.png")}
                height={70}
                width={70}
                alt="logo"
              />{" "}
            </div>
          </ProjectDesc>
        </BottomSectionDiv>
        <BottomSectionDiv
          width={35}
          className="project_container"
          data-aos="fade-up"
        >
          <ProjectBackgroundImg
            alt="CED"
            src={require("../../assets/images/projects/ced/background.jpeg")}
            // filter={true}
          />
          <ProjectDesc
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              color: colors.primary,
            }}
            light={true}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link
                to={"http://cedelivery.com/"}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }}
              >
                <Button
                  props={{
                    color: "white",
                    hover: "transparent",
                    hoverColor: "white",
                  }}
                  style={{
                    ...ButtonProps,
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  <TbWorld color="black" /> website
                </Button>
              </Link>
              <img
                src={require("../../assets/images/projects/ced/logo_min_dark.png")}
                height={70}
                width={70}
                style={{ alignSelf: "flex-end" }}
                alt="logo"
              />
            </div>

            <div>
              <ProjectName>CED</ProjectName>
              <ProjectP>
                This business, specializes in fast and easy delivery services
                for various categories, including food and beverages, documents,
                and electrical gadgets. They offer real-time order tracking,
                multi-order options to save costs, and promise quick delivery,
                even promising to deliver items in a good state. They also offer
                pickup delivery at your doorstep. Their prices are affordable,
                starting at GH₵ 10.00. They have a hotline for customer support
                and are available for all delivery needs.
              </ProjectP>
            </div>
          </ProjectDesc>
        </BottomSectionDiv>
        <BottomSectionDiv
          width={27}
          className="project_container"
          data-aos="fade-left"
        >
          <ProjectBackgroundImg
            alt="Easy poll"
            src={require("../../assets/images/projects/easypoll/background.jpg")}
            style={{ objectFit: "cover" }}
            // filter={true}
          />
          <ProjectDesc
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              color: colors.primary,
            }}
            light={true}
          >
            <span
              style={{
                marginLeft: "auto",
                borderRadius: "50%",
                backgroundColor: "pink",
                height: 50,
                width: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../assets/images/projects/easypoll/logo.png")}
                height={50}
                width={50}
                style={{ alignSelf: "flex-end", borderRadius: "50%" }}
                alt="logo"
              />
            </span>
            <div>
              <ProjectName>easypoll</ProjectName>
              <ProjectP>
                This is a web app that allows users to create polls and share
                them with friends. With faster and realtime feautures, is also
                provide high security and privacy.
              </ProjectP>
            </div>
          </ProjectDesc>
        </BottomSectionDiv>
      </TopSectionContainer>
      <BottomSectionContainer>
        <StatusRow data-aos="fade-up">
          <StatusWidth width={40}>Kenny Royal Website</StatusWidth>
          <StatusWidth width={20} style={{ fontSize: 12 }}>
            Category: BRAND WEBSITE
          </StatusWidth>
          <StatusWidth width={20} style={{ fontSize: 12 }}>
            Status: COMPLETE
          </StatusWidth>
          <StatusWidth
            width={20}
            style={{
              fontSize: 12,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Location: GHANA
            <CgArrowTopRight
              size={22}
              color={"white"}
              style={{ marginLeft: "auto" }}
            />
          </StatusWidth>
        </StatusRow>
        <StatusRow data-aos="fade-up">
          <StatusWidth width={40}>Cutting-Edge Delivery</StatusWidth>
          <StatusWidth width={20} style={{ fontSize: 12 }}>
            Category: DIGITAL SERVICING
          </StatusWidth>
          <StatusWidth width={20} style={{ fontSize: 12 }}>
            Status: COMPLETE
          </StatusWidth>
          <StatusWidth
            width={20}
            style={{
              fontSize: 12,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Location: GHANA
            <CgArrowTopRight
              size={22}
              color={"white"}
              style={{ marginLeft: "auto" }}
            />
          </StatusWidth>
        </StatusRow>
        <StatusRow data-aos="fade-up">
          <StatusWidth width={40}>easypoll</StatusWidth>
          <StatusWidth width={20} style={{ fontSize: 12 }}>
            Category: Survey and Feedback Tools
          </StatusWidth>
          <StatusWidth width={20} style={{ fontSize: 12 }}>
            Status: IN PROGRESS
          </StatusWidth>
          <StatusWidth
            width={20}
            style={{
              fontSize: 12,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Location: UNITED KINGDOM
            <CgArrowTopRight
              size={22}
              color={"white"}
              style={{ marginLeft: "auto" }}
            />
          </StatusWidth>
        </StatusRow>
      </BottomSectionContainer>
    </ProjectsSectionContainer>
  );
};

const ProjectsSectionContainer = styled.div`
  height: 100vh;
  display: flex;
  gap: 5%;
  justify-content: space-between;
  background-color: #151515;
  color: white;
  padding: 1.5%;
  flex-direction: column;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  @media (max-width: 768px) {
    height: 100vh;
    height: max-content;
    flex-direction: column;
  }
`;

const TopSectionContainer = styled.div`
  position: relative;
  flex: 0.7;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  /* background-color: red; */

  @media (max-width: 768px) {
    flex: 1;
    flex-direction: column;
  }
`;

const BottomSectionContainer = styled.div`
  flex: 0.3;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
  /* background-color: white; */
`;

const BottomSectionDiv = styled.div<{ width: number }>`
  width: ${(props) => props.width + "%"};
  border-radius: 30px;
  background-color: #151515;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.10);
  border: 2px solid white; */

  @media (max-width: 768px) {
    width: 100%;
    height: max-content;
    max-height: 500px;
    margin-top: 10px;
  }
`;

const ProjectBackgroundImg = styled.img<{ filter?: true }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 10px;
  transition: all 1s ease-in-out;
  filter: ${(props) => (props.filter ? "grayscale(100%)" : "none")};

  .project_container:hover & {
    width: 110%;
    height: 110%;
    filter: ${(props) => (props.filter ? "grayscale(60%)" : "none")};
  }

  @media (max-width: 768px) {
    object-fit: fill;
  }
`;

const ProjectDesc = styled.div<{ light?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: ${transition};
  z-index: 2;
  padding: 7.5%;

  .project_container:hover & {
    opacity: 1;
    background-color: ${(props) =>
      props.light ? "rgba(256, 256, 256, 0.5)" : "rgba(0, 0, 0, 0.5)"};
  }

  @media (max-width: 768px) {
    background-color: ${(props) =>
      props.light ? "rgba(256, 256, 256, 0.5)" : "rgba(0, 0, 0, 0.5)"};
  }
`;

const ProjectName = styled.h2`
  font-size: 22px;
  margin: 0 0 25px 0;
  /* font-weight: 600; */
`;

const ProjectP = styled.p`
  font-size: 14px;
`;

const StatusRow = styled.div`
  border: 1px solid white;
  border-radius: 30px;
  padding: 15px 30px;
  display: flex;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StatusWidth = styled.span<{ width: number }>`
  width: ${(props) => props.width + "%"};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default ProjectsSection;
