import { styled } from "styled-components";
import { colors } from "../../styles/theme";

const AboutSection = () => {
  return (
    <AboutSectionContainer id="about">
      <AboutSectionDiv style={{padding: "1.5%"}}>
        <p
          style={{
            color: "#888888",
            fontSize: 14,
            padding: "0 10%",
            marginBottom: 50,
          }}
          data-aos="fade-up"
        >
          We offer comprehensive management services throughout the entire
          lifecycle of software projects, including development, design, and
          implementation stages.
        </p>
        <ImageScrollContainer>
          <img
            alt="consult_1"
            src={require("../../assets/images/consult_1.jpeg")}
            style={{ width: "100%", borderRadius: 30 }}
            data-aos="fade-up"
          />
          <img
            alt="consult_1"
            src={require("../../assets/images/development_1.jpeg")}
            style={{ width: "100%", borderRadius: 30 }}
            data-aos="fade-up"
          />
        </ImageScrollContainer>
      </AboutSectionDiv>
      <AboutSectionDiv>
        <AboutImageContainer
        >
          <img
            alt="consult_1"
            src={require("../../assets/images/development_2_1.jpeg")}
            style={{ height: 350, borderRadius: 20 }}
            data-aos="fade-left"
          />
          <img
            alt="consult_1"
            src={require("../../assets/images/development_2_2.jpeg")}
            style={{ height: 350, borderRadius: 20 }}
            data-aos="fade-left"
          />
        </AboutImageContainer>
        <h2
          style={{ fontSize: 18, fontWeight: 600, marginTop: 35, padding: "0 10%" }}
          data-aos="fade"
        >
          About
        </h2>
        <AboutTextContainer style={{padding: "0 10%"}}>
          <AboutTextParagraph data-aos="fade-up">
            CTS is known for handling large and complex projects, fostering
            innovation, embracing cutting-edge technologies, and creating a
            positive impact on clients, employees, and the community.
          </AboutTextParagraph>
          <AboutTextParagraph data-aos="fade-up">
            CTS provides software developers with the convenience and assistance
            of a local company while offering the stability and capabilities of
            a multinational organization.
          </AboutTextParagraph>
        </AboutTextContainer>
        <p style={{ color: colors.primary, fontSize: 30, padding: "0 7.5%" }} data-aos="fade-up">
          Our aspiration is to become the leading provider of top-tier global
          software development services and technical expertise.
        </p>
      </AboutSectionDiv>
    </AboutSectionContainer>
  );
};

const AboutSectionContainer = styled.div`
  height: 100vh;
  /* padding: 1.5%; */
  display: flex;
  gap: 5%;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: 100vh;
    height: max-content;
    flex-direction: column;
  }
`;

const AboutSectionDiv = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex: 100%;
  }
`;

const ImageScrollContainer = styled.div`
  flex: 1;
  border-radius: 30px;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
`;

const AboutTextContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const AboutTextParagraph = styled.p`
  color: #888888;
  font-size: 14px;
  flex: 0.5;
  @media (max-width: 768px) {
    flex: 1;
  }
`;

const AboutImageContainer = styled.div`
  display: flex;
            justify-content: flex-end;
            gap: 5px;
            @media (max-width: 768px) {
              overflow-x: hidden;
            }

`

export default AboutSection;
