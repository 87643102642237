import React from "react";
import { styled } from "styled-components";
import { Title } from "./ServicesSection.component";
import { colors } from "../../styles/theme";
import { AboutTextParagraph } from "./AboutSection.component";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { transition } from "../../styles";

const TestimonySection = () => {
  return (
    <Container id="reviews">
      <Title
        style={{
          width: "100%",
          margin: "1% 0 3% 0",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 10,
          alignItems: "center",
          fontWeight: "bolder",
          color: colors.primary,
        }}
        data-aos="fade"
      >
        <hr
          style={{
            width: 2,
            height: 30,
            border: "none",
            borderRadius: 5,
            backgroundColor: colors.primary,
            margin: 0,
          }}
        />
        Customer Review
      </Title>
      <CardWrapper>
        <CardContainer style={{ backgroundColor: "white" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  backgroundColor: colors.primary,
                  borderRadius: "21% 79% 70% 30% / 33% 38% 62% 67%",
                  padding: "10px 8px 6px 8px",
                  width: "min-content",
                }}
              >
                <img
                  src={require("../../assets/images/inverted-commas.png")}
                  alt="comma"
                  height={15}
                  width={"auto"}
                />
              </span>
              <span
                style={{
                  borderRadius: 20,
                  backgroundColor: colors.secondary,
                  padding: "2px 10px",
                  fontSize: 12,
                }}
              >
                5.0 <BsStarFill style={{ marginLeft: 5 }} />
              </span>
            </div>
            <h3
              style={{
                fontSize: 18,
                fontWeight: 600,
                marginBottom: 10,
                marginTop: 40,
              }}
            >
              Exceptional Web App Development
            </h3>
            <AboutTextParagraph
              style={{ fontSize: 13, marginTop: 0, color: colors.primary }}
            >
              I was thoroughly impressed with the web app development services
              provided by this company. They delivered a sleek and user-friendly
              web app that exceeded my expectations. Highly recommended!
            </AboutTextParagraph>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <img
              src={require("../../assets/images/kennedy.png")}
              style={{
                height: 45,
                width: 45,
                borderRadius: "21% 79% 70% 30% / 33% 38% 62% 67%",
                border: "1px solid " + colors.secondary,
              }}
              alt="Alvin"
            />
            <div>
              <h4 style={{ margin: 0, fontSize: 16, fontWeight: "bold" }}>
                Kennedy Owurani
              </h4>
              <AboutTextParagraph
                style={{
                  margin: "2px 0 0 0",
                  color: colors.primary,
                  fontSize: 12,
                }}
              >
               C.E.O, Kenny Royal LC
              </AboutTextParagraph>
            </div>
          </div>
        </CardContainer>
        <CardContainer
          style={{
            background: `url(${require("../../assets/images/alvin.jpeg")})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "flex-end",
            fontFamily: "Montserrat, sans-serif",
            position: "relative",
            padding: 0,
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(to top, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "8%",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#151515",
                    borderRadius: "21% 79% 70% 30% / 33% 38% 62% 67%",
                    padding: "10px 8px 6px 8px",
                    width: "min-content",
                  }}
                >
                  <img
                    src={require("../../assets/images/inverted-commas.png")}
                    alt="comma"
                    height={15}
                    width={"auto"}
                  />
                </span>
                <span
                  style={{
                    borderRadius: 20,
                    backgroundColor: "#151515",
                    padding: "2px 10px",
                    fontSize: 12,
                    color: "white",
                  }}
                >
                  5.0 <BsStarFill style={{ marginLeft: 5 }} />
                </span>
              </div>
              <h3
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  marginBottom: 10,
                  marginTop: 40,
                  color: "white",
                }}
              >
                 Data Science Wizards!
              </h3>
              <AboutTextParagraph
                style={{ fontSize: 13, marginTop: 0, color: "white" }}
              >
                 The data science solutions offered by this company have been a
              game-changer for our business. Their data analysis and insights
              have helped us make data-driven decisions that have led to
              significant growth. Trustworthy and highly skilled!
                
              </AboutTextParagraph>
            </div>
            <div style={{ marginTop: "auto" }}>
              <h4
                style={{
                  margin: 0,
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Alvin Vanderpuije
              </h4>
              <AboutTextParagraph
                style={{
                  margin: "2px 0 0 0",
                  fontSize: 12,
                  color: "white",
                }}
              >
                Real-Time Analyst, Web Help
              </AboutTextParagraph>
            </div>
          </div>
        </CardContainer>
        <CardContainer
          style={{
            backgroundColor: "black",
            color: "white",
            position: "relative",
          }}
        >
          <img
            src={require("../../assets/images/gustav.jpeg")}
            style={{
              height: 100,
              width: 100,
              borderRadius: "21% 79% 70% 30% / 33% 38% 62% 67%",
              position: "absolute",
              //   border: "1px solid white",
              top: -5,
              left: -10,
            }}
            alt="Alvin"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: 15,
              margin: -5,
            }}
          >
            <h4 style={{ margin: 0, fontSize: 16, fontWeight: "bold" }}>
              Gustav Darko
            </h4>
            <AboutTextParagraph
              style={{
                margin: 0,
                color: "white",
                fontSize: 12,
              }}
            >
              Managing Director, Ruach Media CL
            </AboutTextParagraph>
          </div>
          <div>
            <h3
              style={{
                fontSize: 18,
                fontWeight: 600,
                marginBottom: 10,
                marginTop: 40,
              }}
            >
              Top-Notch Mobile App Development
            </h3>
            <AboutTextParagraph
              style={{ fontSize: 13, marginTop: 0, color: "white" }}
            >
              The mobile app developed by this team is outstanding. It runs
                smoothly, has a beautiful interface, and has greatly improved
                our user engagement. Their expertise in mobile app development
                is unmatched.
            </AboutTextParagraph>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                borderRadius: 20,
                backgroundColor: colors.primary,
                padding: "2px 10px",
                fontSize: 10,
                color: "white",
              }}
            >
              <BsStarFill style={{ marginRight: 5 }} />
              <BsStarFill style={{ marginRight: 5 }} />
              <BsStarFill style={{ marginRight: 5 }} />
              <BsStarFill style={{ marginRight: 5 }} />
              <BsStarHalf />
            </span>
            <img
              src={require("../../assets/images/inverted-commas.png")}
              alt="comma"
              height={20}
              width={"auto"}
              style={{ marginLeft: "auto" }}
            />
          </div>
        </CardContainer>
      </CardWrapper>
    </Container>
  );
};

const Container = styled.div`
  color: ${colors.primary};
  padding: 1.5%;
  margin-bottom: 40px;
`;

const CardWrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 30px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  width: 300px;
  height: 350px;
  border-radius: 10px;
  padding: 2%;
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #dbad5b;
  overflow: hidden;
  transition: ${transition};

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 30px 20px;
  }
`;

export default TestimonySection;
