import React, { useRef } from "react";
import { styled } from "styled-components";
import { colors, theme } from "../../styles/theme";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { TbChevronUpRight } from "react-icons/tb";
import { Button, Link } from "../../styles";
import { scrollTotop } from "../../services/scroll.service";
import { Logo } from "../Navigation";

const ContactsSection = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const submitMail = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const formData = new FormData(e.currentTarget);
      const name = formData.get("name") as string;
      const message = formData.get("body") as string;

      const subject = `CTS enquiry from - ${name}`;
      const body = message;

      const mailtoUrl = `mailto:iziqbek@gmail.com?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      window.open(mailtoUrl, "_blank", "noopener noreferrer");

      if (formRef.current) {
        formRef.current.reset(); // Reset the form values
      }
    } catch (error) {
      console.error("Error submitting mail:", error);
    }
  };

  return (
    <>
      <ContactSectionContainer id="contacts">
        <SectionForm
          style={{
            color: "white",
            backgroundColor: "#151515",
            padding: "3%",
            position: "relative",
          }}
          width={59}
          onSubmit={(e) => submitMail(e)} ref={formRef}
        >
          <CatchPhrase>
            Let's work{" "}
            <span style={{ fontStyle: "italic", marginLeft: 10 }}>
              {" "}
              together{" "}
              <TbChevronUpRight
                style={{ marginLeft: 20, marginTop: -30 }}
                size={30}
              />
            </span>
          </CatchPhrase>
            <TeztInput placeholder="Name" name="name" required />
            {/* <TeztInput placeholder="Email" name="email" required type="email" /> */}
            <TeztInput
              placeholder="Message content"
              name="body"
              required
              style={{ marginTop: 40 }}
            />
            <Button
              style={{ width: "max-content", marginTop: 30 }}
              props={{ color: "black", bakground: "white" }}
              type="submit"
            >
              Send message
            </Button>
          <ContactsImg />
          <div />
        </SectionForm>
        <SectionDiv style={{ color: "black", zIndex: 0 }} width={40}>
          <div style={{ display: "flex", gap: 10 }}>
            <Link
              style={{
                borderRadius: 15,
                border: `1px solid ${colors.primary}`,
                fontSize: 12,
                padding: "7.5px 15px",
                color: "black",
              }}
              to={"mailto:iziqbek@gmail.com"}
            >
              iziqbek@gmail.com
            </Link>
            <Link
              style={{
                borderRadius: 15,
                border: `1px solid ${colors.primary}`,
                fontSize: 12,
                padding: "7.5px 15px",
                color: "black",
              }}
              to={"tel:+233203477216"}
            >
              +233 203 477 216
            </Link>
          </div>
          <p
            style={{
              fontSize: 40,
              flex: 1,
              display: "flex",
              alignItems: "center",
              fontFamily: "NeueHaasDisplay-Light",
              margin: "40px 0",
            }}
          >
            Get in touch
          </p>
          <div style={{ display: "flex", gap: 15 }}>
            <p
              style={{
                fontSize: 12,
                color: "gray",
                margin: 0,
                marginRight: "auto",
                flex: 1,
              }}
            >
              CTS © {new Date().getFullYear()} All rights reserved.
            </p>
            <span>Twitter</span>
            <span>LinkedIn</span>
          </div>
        </SectionDiv>
      </ContactSectionContainer>
    </>
  );
};

const ContactSectionContainer = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 1.5%;

  @media (max-width: 768px) {
    height: 100vh;
    height: max-content;
    flex-direction: column;
  }
`;

const CatchPhrase = styled.h1`
  margin: 0;
  font-weight: normal;
  font-family: "NeueHaasDisplay-Light";
  font-size: 45px;
  z-index: 1;
  width: 70%;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 20px;
  }
`;

const TeztInput = styled.input`
  width: 65%;
  border: none;
  outline: none;
  border-bottom: 2px solid white;
  color: white;
  background-color: transparent;
  padding-bottom: 10px;
  margin-top: 20px;
`;

const SectionDiv = styled.div<{ width: number }>`
  width: ${(props) => props.width + "%"};
  border-radius: 30px;
  background-color: #ededee;
  padding: 2%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid white;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    padding: 5%;
  }
`;

const SectionForm = styled.form<{ width: number }>`
  width: ${(props) => props.width + "%"};
  border-radius: 30px;
  background-color: #ededee;
  padding: 2%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid white;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    padding: 5%;
  }
`;

const ContactsImg = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 70%;
  right: 0;
  object-fit: cover;
  filter: grayscale(100%);
  background: ${`url(${require("../../assets/images/contacts.jpeg")})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
`;

export default ContactsSection;
