import React, { useState } from "react";
import { Button, Link, transition } from "../../styles";
import { styled } from "styled-components";
import { RiMenu4Fill } from "react-icons/ri";
import { FaMinus } from "react-icons/fa";
import AnimateHeight from "react-animate-height";
import { colors } from "../../styles/theme";
import { HiMinus } from "react-icons/hi2";
import {
  scrollIntoViewWithOffset,
  scrollTotop,
} from "../../services/scroll.service";

const Navigation = () => {
  const [dropNave, setDropNav] = useState<Boolean>(false);

  const navigate = (id: string) => {
    setDropNav(false)
    scrollIntoViewWithOffset(`#${id}`, 0);
  };

  return (
    <>
      <NavigationContainer>
        <Logo
          src={require("../../assets/images/logo-black.png")}
          alt="logo"
          style={{ marginRight: "7.5%" }}
          data-aos="fade-right"
          onClick={() => scrollTotop()}
        />
        <BigScreenButtons>
          <span
            style={{ display: "flex", alignItems: "center" }}
            data-aos="fade-right"
          >
            <Button
              style={{ marginRight: 20 }}
              onClick={() => scrollIntoViewWithOffset("#about", 0)}
            >
              ABOUT
            </Button>
            <Button
              style={{ marginRight: 20 }}
              onClick={() => scrollIntoViewWithOffset("#services", 0)}
            >
              SERVICES
            </Button>
            <Button
              style={{ marginRight: 20 }}
              onClick={() => scrollIntoViewWithOffset("#portfolio", 0)}
            >
              PORTFOLIO
            </Button>
            <Button
              style={{ marginRight: 20 }}
              onClick={() => scrollIntoViewWithOffset("#reviews", 0)}
            >
              REVIEWS
            </Button>
            <Button
              style={{ marginRight: 20 }}
              onClick={() => scrollIntoViewWithOffset("#contacts", 0)}
            >
              CONTACTS
            </Button>
          </span>
          <span data-aos="fade-left" style={{ marginLeft: "auto" }}>
            <Button
              props={{
                bakground: "#EAEAEA",
                hover: "#36383C",
                hoverColor: "white",
              }}
              onClick={() => scrollIntoViewWithOffset("#contacts", 0)}
            >
              BOOK A CALL
            </Button>
          </span>
        </BigScreenButtons>
        <SmallScreenNavButton onClick={() => setDropNav(true)} />
      </NavigationContainer>
      <NavigationMobile>
        <AnimateHeight height={dropNave ? "auto" : 0} duration={1000}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                backgroundColor: "white",
                padding: "3% 5%",
                gap: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Logo
                  src={require("../../assets/images/logo-black.png")}
                  alt="logo"
                  style={{ marginRight: "7.5%" }}
                  data-aos="fade-right"
                />

                <HiMinus
                  size={30}
                  color={colors.primary}
                  onClick={() => setDropNav(false)}
                />
              </div>
              <Button
                style={{ paddingRight: 0 }}
                props={{ hover: "transparent" }}
                onClick={()=> navigate("about")}
              >
                ABOUT
              </Button>
              <Button
                style={{ paddingRight: 0 }}
                props={{ hover: "transparent" }}
                onClick={()=> navigate("services")}
              >
                SERVICES
              </Button>
              <Button
                style={{ paddingRight: 0 }}
                props={{ hover: "transparent" }}
                onClick={()=> navigate("portfolio")}
              >
                PORTFOLIO
              </Button>
              <Button
                style={{ paddingRight: 0 }}
                props={{ hover: "transparent" }}
                onClick={()=> navigate("reviews")}
              >
                REVIEWS
              </Button>
              <Button
                style={{ paddingRight: 0 }}
                props={{ hover: "transparent" }}
                onClick={()=> navigate("contacts")}
              >
                CONTACTS
              </Button>
              <div
                style={{
                  border: "0.5px solid " + colors.primary,
                  width: "100%",
                }}
              />
              <Button
                style={{ paddingRight: 0 }}
                props={{ hover: "transparent" }}
                onClick={()=> navigate("contacts")}
              >
                BOOK A CALL
              </Button>
            </div>
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                flex: 1,
                width: "100%",
              }}
              onClick={() => setDropNav(false)}
            ></div>
          </div>
        </AnimateHeight>
      </NavigationMobile>
    </>
  );
};

export const Logo = styled.img`
  height: 30px;

  @media (max-width: 768px) {
    height: 17.5px;
  }
`;

const NavigationContainer = styled.div`
  padding: 1.5% 5%;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 3% 5%;
  }
`;

const BigScreenButtons = styled.span`
  flex: 1;
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SmallScreenNavButton = styled(RiMenu4Fill)`
  display: none;
  margin-left: auto;
  cursor: pointer;
  font-size: 30px;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const NavigationMobile = styled.nav`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0%;
    right: 0%;
    /* background-color: white; */
    z-index: 2;
    /* background-color: rgba(255, 255 255, 0.5); */
    box-shadow: 0px 20px 40px rgb(0 48 73 / 5%), 0px 0px 2px rgb(0 0 0 / 13%);
  }
`;

export default Navigation;
