import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Navigation from "../components/Navigation";
import IntroSection from "../components/Home/IntroSection.component";
import AboutSection from "../components/Home/AboutSection.component";
import ServicesSection from "../components/Home/ServicesSection.component";
import ProjectsSection from "../components/Home/ProjectsSection.components";
import ContactsSection from "../components/Home/ContactsSection.component";
import TestimonySection from "../components/Home/TestimonySection.component";

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
    });
    Aos.refresh();
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        <Navigation />
        <IntroSection />
      </div>
      <AboutSection />
      <ServicesSection />
      <ProjectsSection />
      <TestimonySection />
      <ContactsSection />
    </>
  );
};

export default Home;
