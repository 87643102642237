import { Link as RouoterLink } from "react-router-dom";
import { styled } from "styled-components";

export const transition = "0.5s all ease-in-out";

export const Link = styled(RouoterLink)`
    text-decoration: none;
`

interface IButton {
    props?: {
      bakground?: string;
      hover?: string;
      hoverColor?: string;
      color?: string;
    };
  }
  
export const Button = styled.button<IButton>`
    border: none;
    outline: none;
    padding: 12px 20px;
    font-size: 12px;
    background-color: ${({ props }) => props?.bakground || "transparent"};
    border-radius: 22px;
    /* margin-right: 20px; */
    transition: ${transition};
    font-weight: 600;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    color: ${({ props }) => props?.color || "#36383C"};
  
    &:hover {
      cursor: pointer;
      background-color: ${({ props }) => props?.hover || "#EAEAEA"};
      color: ${({ props }) => props?.hoverColor || "inherit"};
    }
  `;