import React from "react";
import { Button } from "../../styles";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { CgArrowTopRight } from "react-icons/cg";
import { GoProjectSymlink } from "react-icons/go";
import { colors, theme } from "../../styles/theme";
import { styled } from "styled-components";

const IntroSection = () => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 500,
      }}
    >
      <TopSectionContainer>
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        />
        <div
          style={{
            flex: 1,
            padding: "5%",
          }}
        >
          <CatctParagraph data-aos="fade-right">
            Cwin Technology Solutions is a global provider of digital services,<br/>
            offering innovative solutions to enhance all your technological
            needs.
          </CatctParagraph>
          <CatctPhrase data-aos="fade-right">
            The best way to predict
            <br /> the future is to invent it
          </CatctPhrase>
          <div data-aos="fade-left">
            <Button
              props={{
                bakground: "white",
                hover: "#36383C",
                hoverColor: "white",
              }}
              style={{ fontWeight: "normal" }}
            >
              Learn More <HiMiniArrowTopRightOnSquare />
            </Button>
          </div>
        </div>
      </TopSectionContainer>
      <BottomSectionContainer>
        <BottomSectionDiv data-aos="fade-up">
          <span
            style={{
              position: "absolute",
              top: "10%",
              right: "4%",
              borderRadius: 15,
              border: `1px solid ${colors.primary}`,
              fontSize: 10,
              padding: "5px 10px",
            }}
          >
            TEAM
          </span>
          <p
            style={{
              fontSize: 65,
              margin: 0,
              flex: 1,
              display: "flex",
              alignItems: "center",
              fontFamily: "NeueHaasDisplay-Light",
            }}
          >
            10 +
          </p>
          <div
            style={{
              textTransform: "capitalize",
              color: theme.color,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            Employees all over the world
            <span
              style={{
                marginLeft: "auto",
                borderRadius: "50%",
                backgroundColor: colors.primary,
                height: 30,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CgArrowTopRight size={22} color={"white"} />
            </span>
          </div>
        </BottomSectionDiv>
        <BottomSectionDiv data-aos="fade-up">
          <p
            style={{
              fontSize: 65,
              margin: 0,
              flex: 1,
              display: "flex",
              alignItems: "center",
              fontFamily: "NeueHaasDisplay-Light",
            }}
          >
            100 +
          </p>
          <div
            style={{
              textTransform: "capitalize",
              color: theme.color,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            Trusted Clients Internationally
            <span
              style={{
                marginLeft: "auto",
                borderRadius: "50%",
                backgroundColor: colors.secondary,
                height: 30,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <GoProjectSymlink size={22} color={colors.secondary} />
            </span>
          </div>
        </BottomSectionDiv>
        <BottomSectionDiv
          data-aos="fade-up"
          style={{ backgroundColor: colors.primary }}
        >
          <p
            style={{
              fontSize: 65,
              margin: 0,
              flex: 1,
              display: "flex",
              alignItems: "center",
              color: "white",
              fontFamily: "NeueHaasDisplay-Light",
            }}
          >
            25 +
          </p>
          <div
            style={{
              textTransform: "capitalize",
              color: "white",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            International projects annually
            <span
              style={{
                marginLeft: "auto",
                borderRadius: "50%",
                backgroundColor: "white",
                height: 30,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <GoProjectSymlink size={22} color={colors.primary} />
            </span>
          </div>
        </BottomSectionDiv>
      </BottomSectionContainer>
    </div>
  );
};

const TopSectionContainer = styled.div`
  position: relative;
  flex: 0.7;
  width: 100%;
  border-radius: 50px;
  background: ${`url(${require("../../assets/images/home-background.jpeg")})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex: 1;
    padding-top: 100px;
    border-radius: 20px;
  }
`;

const BottomSectionContainer = styled.div`
  flex: 0.3;
  width: 100%;
  padding: 1.5%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BottomSectionDiv = styled.div`
  width: 32%;
  border-radius: 30px;
  background-color: #ededee;
  padding: 2%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.10);
  border: 2px solid white; */

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    padding: 5%;
  }
`;

const CatctPhrase = styled.p`
  color: white; 
  font-size: 50px;

  @media (max-width: 768px) {
    font-size: 35px;
  }
`

const CatctParagraph = styled.p`
  color: white; 

  @media (max-width: 768px) {
    font-size: 15px;
  }
`

export default IntroSection;
