const scrollTotop = () => {
  try {
    window.scrollTo({ top: 0, behavior: "smooth", });
  } catch (e) {}
};

const scrollIntoViewWithOffset = (selector: any, offset: number) => {
  try {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  } catch (e) {}
};

export { scrollTotop, scrollIntoViewWithOffset };
